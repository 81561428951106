.background {
  position: fixed;
  background: #0004;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  animation: fadeIn 50ms ease forwards;
}

.box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-green-lightest);
  padding: 3rem 5rem;
  min-width: 55rem;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  z-index: 2;
  opacity: 0;
  animation: fadeIn 100ms ease forwards;
}

.box .header {
  margin-top: 0;
  margin-bottom: 1rem;
}

.subTitle {
  font-size: 1.5rem;
  margin: 1.5rem 0;
}

.subTitleLink {
  color: var(--color-green-dark);
  font-weight: 600;
  text-decoration: none;
}

.createSubjectPlanMessage {
  font-size: 1.5rem;
  margin: 5rem 0;
}

.select__container {
  min-width: 20rem;
}

.select__container label {
  display: block;
  font-size: 1.5rem;
  margin: 1rem 0 0.5rem 0;
}

.select__container select {
  font-size: 1.5rem;
  width: 100%;
  font-family: inherit;
  border: var(--stroke-width-standard) solid var(--color-green-dark);
  padding: 0.5rem;
  padding-right: 3rem;
  background: var(--color-text-white);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border-radius: 0;
}

.dropdownArrow {
  color: var(--color-green-dark);
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  transform: translate(-2rem, 1rem);
  pointer-events: none;
}

.showSubjectPlanLink {
  display: inline-block;
  font-size: 1.5rem;
  margin: 1rem 0;
  text-decoration: none;
  color: var(--color-green-dark);
  will-change: transform;
}

.showSubjectPlanLink:hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.showSubjectPlanLink:active {
  transform: scale(0.98);
}

.showSubjectPlanLink svg {
  width: 3rem;
  height: 1.8rem;
  margin-right: 0.3rem;
  position: relative;
  top: 0.5rem;
}

.showSubjectPlanLink span::after {
  content: "";
  display: block;
  background-color: var(--color-green-dark);
  position: relative;
  top: 0.2rem;
  height: 0.0625rem;
  margin-left: 3.5rem;
}

.dates__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  grid-gap: 2rem;
  margin-bottom: 2rem;
}

.dates__container label {
  display: block;
  font-size: 1.5rem;
  margin: 1rem 0 0.5rem 0;
}

.dates__container input {
  border: var(--stroke-width-standard) solid var(--color-green-dark);
  background: var(--color-text-white);
  font-family: inherit;
  font-size: 1.5rem;
  padding: 0.5rem;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.dates__container input.invalid {
  border-color: #f00;
}

.error {
  color: #f00;
  font-size: 1.5rem;
  display: block;
}

.buttons__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.buttons__container button {
  border: none;
  background: none;
  display: inline-block;
  font-size: 1.5rem;
  height: 3.875rem;
  margin-top: 2rem;
  font-weight: bold;
  color: var(--color-green-dark);

  transition: 100ms ease;
  will-change: transform;
}

.buttons__container button:hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.buttons__container button:active {
  transform: scale(0.98);
}

.buttons__container .save {
  background: var(--color-green-dark);
  color: var(--color-text-white);
  font-weight: normal;
  padding: 0.5rem 1rem;
  width: 12.5rem;
  margin-right: 1.5rem;
}

.buttons__container .cancel {
  margin-right: 2rem;
}

.spinner__container {
  min-height: 30rem;
}

@media screen and (max-width: 900px) {
  .box {
    min-width: 90%;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
