.container {
  opacity: 0;
  transform: translateY(0.4rem);
  animation: fadeIn 500ms 100ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nothingToShow {
  padding-top: 5rem;
  font-size: 1.5rem;
  font-weight: normal;
  text-align: center;
  color: var(--color-green-dark);
}

.selects {
  margin-top: 2rem;
  max-width: 50rem;
}
