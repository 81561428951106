.container {
  min-width: 20rem;
}

.label {
  display: block;
  font-size: 1.5rem;
  margin: 1rem 0 0.5rem 0;
}

.select {
  font-size: 1.5rem;
  width: 100%;
  font-family: inherit;
  border: var(--stroke-width-standard) solid var(--color-green-dark);
  padding: 0.5rem;
  padding-right: 3rem;
  background: var(--color-text-white);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border-radius: 0;
}

.dropdownArrow {
  width: 1.4rem;
  height: 1.4rem;
  color: var(--color-green-dark);
  position: absolute;
  transform: translate(-2.2rem, 1rem);
  pointer-events: none;
}
