.container {
  min-width: 20rem;
  margin-bottom: 1rem;
}

.label {
  display: block;
  font-size: 1.5rem;
  margin: 0;
}

.select {
  color: #000;
  font-size: 1.5rem;
}
