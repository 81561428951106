.container {
  margin-top: 7rem;
  font-size: 0.875rem;
  opacity: 0;
  transform: translateY(0.4rem);
  animation: fadeIn 500ms 100ms ease forwards;
  overflow: auto;
}

.calendar {
  display: flex;
  min-width: 65rem;
}

.headerRow {
  display: flex;
  min-width: 65rem;
}

.headerRow .planHeader {
  width: 17rem;
  padding: 0.2rem;
  border-right: 3px solid #000;
  opacity: 0;
  transform: translateY(0.4rem);
  animation: fadeIn 500ms 100ms ease forwards;
}

.headerRow .planHeader .title {
  font-size: 1.5rem;
}

.headerRow .planHeader .subtitle > span {
  display: block;
  font-size: 1.125rem;
}

.headerRow.row1 .planHeader {
  border-top: 2px solid #000;
}

.headerRow .weekHeader {
  display: flex;
  align-items: flex-end;
  padding: 0.2rem;
  padding-bottom: 0.4rem;
  border-left: 2px solid #000;
  border-right: 3px solid #000;
  width: 4rem;
}

.headerRow.row1 .weekHeader {
  border-top: 2px solid #000;
}

.weekNumbersColumn {
  border: 2px solid #000;
  border-right: 3px solid #000;
  width: 4rem;
}

.weekNumberCell {
  height: 3rem;
  border-bottom: 1px solid #000;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}

.weekNumberCell.isLastWeekOfYear {
  border-bottom: 3px solid #000;
}

.weekNumberCell:last-child {
  border: none;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
