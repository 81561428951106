.blockBodyScroll {
  overflow: hidden;
}

.background {
  position: fixed;
  background: #0004;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  animation: fadeIn 50ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-green-lightest);
  padding: 3rem 5rem;
  min-width: 50rem;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  z-index: 2;
  opacity: 0;
  animation: fadeIn 100ms ease forwards;
}

.spinner__container {
  min-height: 29.2rem;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.box .header {
  margin-top: 0;
  margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {
  .box {
    min-width: 90%;
  }
}
