.background {
  background: linear-gradient(
    90deg,
    var(--color-green-lightest) 50%,
    var(--color-green-light) 50%
  );
}

.main {
  opacity: 0;
  animation: fadeIn 300ms 100ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.nav__variable {
  --color-nav-background: var(--color-green-dark);
  --color-nav-text: var(--color-text-white);
}

@media screen and (max-width: 900px) {
  .background {
    background: var(--color-green-lightest);
  }
}
