.mainContainer {
  display: inline-block;
  font-size: 1.5rem;
  width: 48%;
  background: var(--color-text-white);
  border: var(--stroke-width-standard) solid var(--color-green-dark);
  padding: 0.5rem;
  margin-bottom: 1rem;
  line-height: normal;
}

.mainContainer p {
  margin: 0;
  margin-bottom: 1em;
}

.toolbarContainer {
  border-bottom: var(--stroke-width-standard) solid var(--color-green-dark);
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.toolbarContainer button {
  border: none;
  background: var(--color-green-light);
  margin-right: 0.5rem;
  width: 3rem;
  height: 2.5rem;
  font-size: 1.2rem;
  transition: 100ms ease;
  will-change: transform;
}

.toolbarContainer button:hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.toolbarContainer button:active {
  transform: scale(0.98);
}

.toolbarContainer button.boldButton {
  font-weight: bold;
}

/* .toolbarContainer button.italicButton {
  font-style: italic;
} */

.toolbarContainer button.active {
  background: var(--color-green-dark);
  color: var(--color-text-white);
}

@media screen and (max-width: 900px) {
  .mainContainer {
    width: 100%;
    margin-bottom: 0;
  }
}
