.row > td:first-child {
  color: var(--color-green-dark);
}

.row a {
  color: inherit;
  text-decoration: none;
}

.percentageIcon {
  position: relative;
  left: -1.4em;
}

.percentageIcon:hover::before {
  transform: scale(1.2);
  transition: 50ms ease-out;
}

.percentageIcon:active::before {
  transform: scale(0.98);
}

.percentageIcon::before {
  content: " ";
  position: absolute;
  top: 0.2em;
  width: 1em;
  height: 1em;
  background: conic-gradient(
    var(--color-green-dark) 0 calc(360deg * var(--percentage, 0)),
    var(--color-green-light) 0deg
  );
  border-radius: 50%;
  will-change: transform;
  transition: 200ms ease-out;
}

.labels {
  max-width: 30rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icons button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.icons button:hover svg {
  transform: scale(1.1);
  transition: 50ms ease;
}

.icons button:active svg {
  transform: scale(0.98);
}

.icons svg {
  position: relative;
  top: 0.1em;
  height: 1em;
  width: 2em;
  will-change: transform;
  transition: 100ms ease;
}

.freezeSubject {
  min-width: 20rem;
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 800px) {
  .freezeSubject {
    min-width: 100%;
    max-width: 100%;
  }
}