.container {
  display: flex;
  border-bottom: 1px solid #000;
  height: 3rem;
}

.day {
  background: none;
  border: none;
  border-right: 1px solid #828282;
  padding: 0;
  display: block;
  text-align: left;
  cursor: default;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

a.day {
  color: inherit;
  cursor: pointer;
}

.weekDay-5 {
  border: none;
}

.weekDay-6,
.weekDay-7 {
  border: none;
  border-left: 1px solid #000;
  flex-basis: 50%;
  background: var(--color-green-light);
}

.hasPlan:not(.weekDay-6):not(.weekDay-7) {
  background: var(--planColor);
}

.hasPlan:not(.weekDay-6):not(.weekDay-7):not(.isLastDayOfPlan) {
  border-color: #fff0;
}

.planTitle {
  position: absolute;
  display: block;
  padding-left: 0.3rem;
  width: calc(100% * var(--titleWidth));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
  pointer-events: none;
}

.endOfMonthMarker {
  display: block;
  background: #8f8f8f;
  width: 0.31rem;
  height: 100%;
  position: absolute;
  right: 0;
}

.isLastWeekOfYear {
  border-bottom: 3px solid #000;
}

.day.hasWriteAccessToYearPlan:not(.weekDay-6):not(.weekDay-7) {
  cursor: pointer;
}

.hasWriteAccessToYearPlan:not(.hasPlan):not(.weekDay-6):not(.weekDay-7):hover:after {
  position: absolute;
  content: "+";
  text-align: center;
  width: 100%;
  cursor: pointer;
}
