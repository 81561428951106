.background {
  background: linear-gradient(
    90deg,
    var(--color-green-lightest) 50%,
    var(--color-green-light) 50%
  );
}

.nav__variable {
  --color-nav-background: var(--color-green-dark);
  --color-nav-text: var(--color-text-white);
}

.container {
  padding: 0 var(--padding-site);
  opacity: 0;
  transform: translateY(0.4rem);
  animation: fadeIn 500ms 100ms ease forwards;
  display: flex;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.plans {
  margin-top: 4rem;
  width: 48%;
}

.plans:nth-child(2) {
  margin-left: 3rem;
}

.plans__header {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.cards__container {
  display: flex;
  flex-direction: column;
}

.nothingToShow__container {
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nothingToShow__text {
  font-weight: normal;
  text-align: center;
  color: var(--color-green-dark);
}

.card {
  background: var(--color-accent-yellow);
  text-decoration: none;
  color: inherit;
  max-width: -webkit-max-content;
  max-width: max-content;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.title {
  color: var(--color-green-dark);
  font-size: 2.25rem;
  padding-right: 1.5rem;
  font-weight: normal;
}

.yearPlanCard {
  font: inherit;
  text-decoration: none;
}

.yearPlanCard .title {
  margin-top: 1rem;
}

.yearPlanCard:not(:last-child) .title {
  margin-bottom: 0;
}

.card:nth-child(2n) {
  background: var(--color-accent-melon);
}

.card:nth-child(3n) {
  background: var(--color-accent-coral);
}

.card .subject {
  margin: 0.2rem 0;
  background: rgba(255, 255, 255, 0.4);
  /* Fallback for Safari */
  width: intrinsic;
  /*  */
  width: max-content;
  max-width: 100%;
  padding: 0.2rem 0.5rem;
}

.card .subject:nth-child(2) {
  margin-top: 0.9rem;
}

.card .title {
  margin: 0;
}

.arrow {
  padding-left: 1rem;
}

.card .arrow > svg,
.yearPlanCard .arrow > svg {
  transition: 100ms ease-out;
}

.card:hover .arrow > svg,
.yearPlanCard:hover .arrow > svg {
  transform: translateX(0.8rem);
}

.newPlan__button {
  border: none;
  background: var(--color-green-dark);
  color: var(--color-text-white);
  font-size: 1.5rem;
  padding: 1rem;
}

.newPlan__button:hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.newPlan__button:active {
  transform: scale(0.98);
}

.yearSelectorContainer {
  display: inline-block;
  border-bottom: 1px solid #000;
  margin: 0 0.6rem;
}

.yearSelector {
  font: inherit;
  background: none;
  border: none;
  padding: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  padding-right: 1.5rem;
}

.dropdownArrow {
  width: 1.1rem;
  height: 1.1rem;
  position: absolute;
  transform: translate(-1.3rem, 0.7rem);
  pointer-events: none;
}

@media screen and (max-width: 900px) {
  .background {
    background: var(--color-green-lightest);
  }

  .container {
    flex-direction: column;
  }

  .plans {
    width: 100%;
  }

  .plans:nth-child(2) {
    margin-left: 0rem;
  }
}
