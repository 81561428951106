.title {
  margin: 1rem 0;
}

.subtitles {
  display: flex;
  flex-wrap: wrap;
}

.subtitles h2 {
  font-size: 1.5rem;
  margin-top: 0;
  margin-right: 3rem;
}

.optionButtons {
  margin-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
}

.optionButtons button {
  border: none;
  background: none;
  color: var(--color-green-dark);
  font-size: 1.5rem;
  margin-right: 4rem;
}

.optionButtons button svg {
  width: 3rem;
  height: 2rem;
  margin-right: 0.3rem;
  position: relative;
  top: 0.8rem;
}

.optionButtons button:hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.optionButtons button:active {
  transform: scale(0.98);
}

.optionButtons button span::after {
  content: "";
  display: block;
  background-color: var(--color-green-dark);
  position: relative;
  top: 0.2rem;
  height: 0.0625rem;
  margin-left: 3.5rem;
}

.addPlanButton {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.addPlanButton button {
  background: none;
  border: none;
  display: block;
  color: var(--color-green-dark);
  font-size: 1.5rem;
  font-weight: bold;
  transition: 100ms ease;
  will-change: transform;
}

.addPlanButton button:hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.addPlanButton button:active {
  transform: scale(0.98);
}
