.nav__variable {
  --color-nav-background: var(--color-green-light);
}

.container {
  opacity: 0;
  transform: translateY(0.4rem);
  animation: fadeIn 500ms 100ms ease forwards;
  white-space: pre-wrap;
  word-break: break-word;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.container__summary {
  background: var(--color-green-light);
  position: relative;
  left: calc(0rem - var(--padding-site));
  width: 100vw;
  padding: 1rem var(--padding-site) 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.container__summary .title {
  margin: 0;
}

.container__summary .subHeader {
  margin-bottom: 0;
}

.text > p.grade {
  margin-bottom: 0.5rem;
}

.text > p.time {
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
}

.subject {
  font-weight: bold;
  display: block;
  margin-top: 0;
  padding-right: 4rem;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.text {
  flex-basis: 65%;
}

.text p {
  font-size: 1.5rem;
  white-space: pre-wrap;
  margin-top: 0;
  margin-bottom: 2.5rem;
}

.mainText__container {
  background: #d1f0d4;
  position: relative;
  left: calc(0rem - var(--padding-site));
  width: 100vw;
  padding: 1.5rem var(--padding-site) 3rem;
}

.mainText__body {
  max-width: 50rem;
  margin: 0 auto;
}

.mainText__body .subHeader {
  font-size: 2.25rem;
  font-weight: 400;
  margin: 0;
}

.mainText__body p {
  font-size: 1.5rem;
  white-space: pre-wrap;
  margin-top: 0;
  margin-bottom: 2.5rem;
}

.activities {
  max-width: 50rem;
  margin: 2rem auto;
  position: relative;
}

.activities .sideLine {
  display: block;
  width: 0.25rem;
  height: calc(100% - 1rem);
  background: var(--color-accent-yellow);
  position: absolute;
  transform: translate(-1.25rem, 1rem);
}

.activities .sideLine.researchPhaseSideLine {
  background: var(--color-accent-melon);
}

.activities .sideLine:before {
  content: "";
  display: block;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background: inherit;
  transform: translate(calc(-50% + 0.125rem), -0.25rem);
}

.activities:after {
  content: "";
  display: block;
  position: absolute;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background: var(--color-accent-yellow);
  transform: translate(calc(-50% - 1.25rem + 0.125rem), -100%);
}

.activities.researchActivities:after {
  background: var(--color-accent-melon);
}

.buttons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: 1rem;
  margin-top: 1rem;
}

.button {
  background: none;
  border: none;
  color: var(--color-green-dark);
  font-size: 1.5rem;
  transition: 100ms ease;
  will-change: transform;
  display: inline-block;
  text-decoration: none;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button:last-child {
  margin-left: 1rem;
}

.button:hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.button:active {
  transform: scale(0.98);
}

.button svg {
  width: 3rem;
  height: 2rem;
  position: relative;
  top: 0.4rem;
  fill: var(--color-green-dark);
}

.button span::after {
  content: "";
  display: block;
  background-color: var(--color-green-dark);
  position: relative;
  top: 0.2rem;
  height: 0.0625rem;
  margin-left: 3rem;
}

.sectionHeader {
  margin-bottom: 1rem;
}

.container__labels {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.25rem;
}

.container__labels .label {
  background: var(--color-green-light);
  padding: 0.3rem 1rem;
  margin: 0.25rem;
  font-size: 1.5rem;
}

.container__assessment {
  max-width: 50rem;
  margin: 0 auto;
}

.container__assessment .header {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.container__assessment p {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 2rem;
}

@media screen and (max-width: 900px) {
  .container__summary {
    flex-direction: column;
  }

  .buttons {
    justify-content: flex-start;
  }

  .container__assessment {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .activities .sideLine {
    transform: translate(-1rem, 1rem);
  }

  .activities .sideLine:before {
    transform: translate(calc(-50% + 0.125rem), -0.25rem);
  }

  .activities:after {
    transform: translate(calc(-50% - 0.875rem), -100%);
  }
}
