.container {
  margin-top: 10rem;
  width: 100%;
}

.line1 {
  display: flex;
  justify-content: center;
  fill: var(--color-text-black);
}

.line1 svg {
  width: 10rem;
  height: 6rem;
  margin: 1rem 1.5rem;
}

.line2 {
  background: var(--color-green-dark);
  fill: var(--color-text-white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
}

.line2 svg {
  height: 4rem;
  width: 12rem;
}

.link {
  font-size: 1.125rem;
  color: var(--color-text-white);
  margin-bottom: 1.5rem;
  text-decoration: underline;
}

@media only print {
  .container {
    display: none;
  }
}
