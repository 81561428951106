.label {
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.4rem 0;
  text-align: left;
  display: flex;
  align-items: flex-end;
  transition: transform 0.2s ease, background 0.5s ease;
  width: max-content;
  color: var(--color-green-dark);
}

.label:hover {
  transform: scale(1.04);
  transition: 0.1s ease;
  background: var(--color-green-light);
}

.label:active {
  transform: scale(0.98);
}

.label.checked {
  background: var(--color-green-light);
}

.label input {
  opacity: 0;
  position: absolute;
  width: 1em;
  height: 1em;
}

.label input:focus-visible + * {
  outline: 5px auto var(--color-green-dark);
}

.icon svg {
  opacity: 0;
  margin-left: 0.5rem;
  transform: scale(1.3);
  stroke-width: 0.15rem;
  position: relative;
  top: 0.3rem;
  transition: 0.1s ease-in;
}

.checked .icon svg {
  opacity: 1;
}
