.background {
  background: linear-gradient(
    90deg,
    var(--color-green-lightest) 50%,
    var(--color-green-light) 50%
  );
}

.main {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  opacity: 0;
  transform: translateY(0.4rem);
  animation: fadeIn 500ms 100ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.title {
  font-size: 4rem;
  font-weight: bold;
  margin: 2rem 0;
  transform: translateX(-0.2ch);
}

.subTitle {
  font-size: 2.25rem;
  margin: 2rem 0;
}

.button {
  background: var(--color-green-dark);
  border: none;
  color: var(--color-text-white);
  font-size: 1.5rem;
  padding: 1rem 2rem 1.5rem;
  margin: 2rem 0;
  will-change: transform;
}

.button img {
  height: 2rem;
  width: auto;
  position: relative;
  top: 0.3rem;
  margin-left: 0.3rem;
}

.button:disabled {
  opacity: 0.7;
}

.button:not(:disabled):hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.button:not(:disabled):active {
  transform: scale(0.98);
}

.footer {
  text-align: center;
  background: var(--color-green-dark);
  color: var(--color-text-white);
  width: 100%;
  min-height: 20vh;
  padding: 3rem 1rem 1rem;
  white-space: pre-wrap;
}

.logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.logos svg {
  width: 10rem;
  height: 6rem;
  margin: 0.5rem 1rem;
  fill: var(--color-text-white);
}
