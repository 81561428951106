.container {
  padding: 1rem 0;
  min-height: 10rem;
}

.container .title {
  margin: 0;
  background: var(--color-accent-yellow);
  display: inline-block;
  padding: 0.5rem 1.5rem;
  border-radius: 2.3rem;
  transform: translate(calc(-50% - 1rem), 0);
}

.container.researchPhase .title {
  background: var(--color-accent-melon);
}

.container .section__header {
  text-transform: uppercase;
  margin-bottom: 0.2rem;
}

.container p {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 2.5rem;
  white-space: pre-wrap;
  word-wrap: break-word;
}

@media screen and (max-width: 900px) {
  .container .title {
    transform: translate(-3rem, 0);
  }
}

@media screen and (max-width: 450px) {
  .container .title {
    transform: translate(-1.5rem, 0);
  }
}
