.background {
  position: fixed;
  background: #0004;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  animation: fadeIn 50ms ease forwards;
}

.box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-green-lightest);
  padding: 3rem 5rem;
  min-width: 55rem;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  z-index: 2;
  opacity: 0;
  animation: fadeIn 100ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.box .header {
  margin-top: 0;
  margin-bottom: 2rem;
}

.selector__container {
  width: calc(50% - 1rem);
  margin-bottom: 0.5rem;
}

.selector__container.double {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 2rem;
}

.input__container {
  width: 100%;
}

.input__container label {
  display: block;
  font-size: 1.5rem;
  margin: 1.5rem 0 0.2rem 0;
}

.input__container input {
  border: var(--stroke-width-standard) solid var(--color-green-dark);
  background: var(--color-text-white);
  font-family: inherit;
  font-size: 1.5rem;
  padding: 0.5rem;
  width: 100%;
}

.buttons__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.buttons__container button {
  border: none;
  background: none;
  display: inline-block;
  font-size: 1.5rem;
  height: 3.875rem;
  margin-top: 2rem;
  font-weight: bold;
  color: var(--color-green-dark);

  transition: 100ms ease;
  will-change: transform;
}

.buttons__container button:hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.buttons__container button:active {
  transform: scale(0.98);
}

.buttons__container .save {
  background: var(--color-green-dark);
  color: var(--color-text-white);
  font-weight: normal;
  padding: 0.5rem 1rem;
  width: 12.5rem;
  margin-right: 1.5rem;
}

.spinner__container {
  min-height: 29.2rem;
}

@media screen and (max-width: 900px) {
  .box {
    min-width: 90%;
  }

  .selector__container {
    width: 100%;
  }
}
