.container {
  margin-bottom: 1.5rem;
  max-width: 75rem;
}

.dropdownArrow {
  margin-left: 0.6rem;
  position: relative;
  top: 0.2rem;
  transition: 300ms ease;
}

.dropdownArrow.open {
  transform: rotate(180deg);
}

.container button.expandButton {
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  background: none;
  border: none;
  border-bottom: 2px solid var(--color-green-dark);
  padding: 0;
  color: var(--color-green-dark);
  text-align: left;
  padding-bottom: 0.3rem;
  line-height: 1.4;
}

.content__container {
  padding-top: 1rem;
  margin-bottom: 2.5rem;

  /* Allows line breaking using \n in translation strings   */
  white-space: pre-line;
}
