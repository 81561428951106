.activities__container {
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.activities__container .header {
  width: 48%;
  display: inline-block;
  margin-top: 0;
}

.activity .header {
  display: flex;
  justify-content: space-between;
}

.activity .header .buttons {
  display: flex;
  align-items: center;
}

.activity .orderButtonContainer {
  margin-right: 1rem;
  display: flex;
}

.activity .orderButtonContainer .orderButton {
  padding: 0.2rem;
}

.activity .orderButtonContainer .orderButton:disabled {
  cursor: initial;
}

.activity .orderButtonContainer .orderButton:disabled svg {
  fill: var(--color-green-light);
}

.activity label:first-of-type {
  margin-top: 0.5rem;
}

.collapseDescriptions__container {
  margin-bottom: 2.5rem;
}

.description {
  display: inline-block;
  width: 45%;
  margin-left: 4%;
  vertical-align: top;

  /* Allows line breaking using \n in translation strings   */
  white-space: pre-line;
}

.description div {
  margin-top: 30px;
  margin-bottom: 20px;
  height: 2px;
  width: 100%;
  background: #000;
}

.description span p {
  margin-bottom: 0;
}

/*
* This used to be a "position:absolute" but is now a hack
* around Safari failing to go back to "absolute" after
* media query change to "relative"
*/
.activities__mainDescription {
  margin-bottom: -150%;
}

.activity::after {
  content: "";
  display: block;
  height: var(--stroke-width-standard);
  width: 48%;
  background-color: var(--color-green-dark);
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.button {
  background: none;
  border: none;
  display: block;
  transition: 100ms ease;
  will-change: transform;
}

.button:not(:disabled):hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.button:active {
  transform: scale(0.98);
}

.button__remove {
  color: var(--color-green-dark);
  padding-right: 0;
}

.button__addActivity {
  color: var(--color-green-dark);
  font-size: 1.5rem;
  font-weight: bold;
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
  .activities__container.research {
    margin-bottom: 15rem;
  }
}

@media screen and (max-width: 900px) {
  .activities__container .header {
    width: 100%;
  }

  .activities__container .activity:first-of-type {
    margin-top: 0;
  }

  .activity::after {
    width: 100%;
    margin-top: 2rem;
  }

  .description {
    display: block;
    width: 100vw;
    position: relative;
    left: calc(0rem - var(--padding-site));
    margin: 0;
    padding: 1rem var(--padding-site) 2rem;
    background: var(--color-green-light);
    font-size: 1.5rem;
  }
}
