.notificationBox {
  background: var(--color-accent-yellow);
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  max-width: 30rem;
  width: 100%;
  opacity: 0;
  transform: translateY(0.5rem);
  animation: animateIn 500ms ease forwards;
}

@keyframes animateIn {
  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}

.closeButton {
  display: block;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  align-self: flex-end;
  padding: 0;
  margin: 0;
  margin-right: 0.5rem;
}

.content {
  padding: 0.5rem 1.5rem;
}

.content .title {
  font-weight: bold;
}

.expandButton {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
}

.expandButton .chevron {
  position: relative;
  top: 0.3rem;
  margin-left: 0.5rem;
  transition: 250ms ease;
}

.expandButton .chevron.expanded {
  transform: rotate(180deg);
}

.description {
  margin-top: 0.5rem;
}
