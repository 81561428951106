@import-normalize;

*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --color-green-lightest: #f0faf1;
  --color-green-light: #b3e6b8;
  --color-green-middle: #308f3a;
  --color-green-dark: #006231;

  --color-accent-yellow: #f8e16c;
  --color-accent-melon: #ffc2b4;
  --color-accent-coral: #fb8f67;

  --color-text-black: #000;
  --color-text-white: #fff;
  --color-text-error: #b32;

  --color-nav-background: #fff0;
  --color-nav-text: var(--color-text-black);

  --padding-site: 4rem;

  --stroke-width-standard: 0.1875rem;
  --stroke-width-underline: 0.25rem;
  --stroke-width-underline-nav: 0.375rem;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Encode Sans Semi Condensed", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  background: var(--color-green-lightest);
  overflow-x: hidden;
}

main {
  padding: 0 var(--padding-site);
  min-height: calc(100vh - 25rem);
}

button {
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: inherit;
}

h1 {
  font-size: 3rem;
  font-weight: 400;
}

h2 {
  font-size: 2.25rem;
  font-weight: 400;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

/* Prevent focus outline appearing
on element after mouse click */
:focus:not(:focus-visible) {
  outline: 0;
}

input,
textarea,
select {
  border-radius: 0;
  background: #fff;
  color: inherit;
}

@media screen and (max-width: 1500px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  :root {
    font-size: 11px;
    --padding-site: 2rem;
  }
}

@media only print {
  :root {
    font-size: 10px;
  }
}
