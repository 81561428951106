.container {
  opacity: 0;
  transform: translateY(0.4rem);
  animation: fadeIn 500ms 100ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.selectors__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 3rem;
  margin-left: -1rem;
  margin-right: -1rem;
}

.selectors__container > * {
  flex-grow: 1;
  margin: 0 1rem;
}

.content {
  position: relative;
  overflow: hidden;
}

.table__container {
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
}

.table__container table {
  width: 100%;
  table-layout: auto;
  font-size: 1.5rem;
  border-collapse: collapse;
}

.table__container thead {
  text-align: left;
  color: var(--color-green-dark);
}

.table__container th,
.table__container td {
  padding: 0.5rem;
}

.table__container td:not(:last-child) {
  padding-right: 2rem;
}

.table__container tr {
  border-bottom: 1px solid var(--color-green-light);
}

.table__container tbody > tr:hover,
.table__container tbody > tr:hover .freeze,
.table__container tbody > tr:hover td:nth-child(2) {
  background: #fff;
}

.nothingToShow {
  padding-top: 5rem;
  font-size: 1.5rem;
  font-weight: normal;
  text-align: center;
  color: var(--color-green-dark);
}

@media screen and (min-width: 800px) {
  .table__container table {
    background: linear-gradient(
      90deg,
      var(--color-green-lightest) 0%,
      #fff0 10% 90%,
      var(--color-green-lightest) 100%
    );
  }

  .freeze {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: var(--color-green-lightest);
    z-index: 1;
    min-width: 20rem;
    max-width: 20rem;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .table__container tr td:nth-child(2),
  .table__container tr th:nth-child(2) {
    background: linear-gradient(
      90deg,
      var(--color-green-lightest) 10%,
      #fff0 20% 100%
    );
    z-index: 0;
  }

  .insetShadow {
    position: absolute;
    width: calc(100% - 20rem);
    height: 100%;
    transform: translateX(20rem);
    pointer-events: none;
    background: radial-gradient(
        farthest-side at -30% 50%,
        rgba(0, 0, 0, 0.3) 10%,
        rgba(0, 0, 0, 0)
      ),
      radial-gradient(
          farthest-side at 130% 50%,
          rgba(0, 0, 0, 0.3) 10%,
          rgba(0, 0, 0, 0)
        )
        100% 0;
    background-repeat: no-repeat;
    background-size: 1.5rem 100%, 1.5rem 100%;
  }
}

@media screen and (max-width: 800px) {
  .table__container table {
    border-collapse: separate;
    border-spacing: 0 3rem;
  }

  .table__container thead {
    display: none;
  }

  .table__container tr > td {
    display: block;
    margin-left: 2rem;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .table__container tr > td:first-child {
    margin-top: 2rem;
  }

  .table__container tr > td:last-child {
    margin-bottom: 2rem;
  }

  .table__container tr {
    background: #fff;
  }
}

.sortButton {
  border: none;
  background: none;
  color: inherit;
  font-weight: inherit;
  padding: 0;
  margin: 0;
  text-align: left;
}

.sortButton span {
  padding-left: 0.2em;
  position: relative;
  bottom: -0.05em;
  visibility: hidden;
}

.sortButton span.visible {
  visibility: visible;
}
