.container {
  background-color: var(--color-green-dark);
  position: relative;
  left: calc(0rem - var(--padding-site));
  width: 100vw;
  padding: 1rem var(--padding-site);
  color: var(--color-text-white);
  opacity: 0;
  animation: fadeIn 800ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.container p {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  white-space: pre-wrap;
  overflow: hidden;
  max-width: 70rem;
}

.animateOpen {
  animation: openDropDown 2s ease-out;
}

@keyframes openDropDown {
  0% {
    max-height: 0vh;
  }
  100% {
    max-height: 100vh;
  }
}

.title {
  margin-bottom: 0;
  margin-top: 0;
  white-space: pre-wrap;
  word-break: break-word;
}

.subject {
  font-weight: bold;
  display: block;
  margin-top: 0;
  padding-right: 4rem;
  font-size: 1.5rem;
  margin-bottom: 0;
}

.grade {
  display: block;
  margin-top: 0;
  padding-right: 4rem;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.button__showMore {
  display: block;
  border: none;
  background: none;
  color: inherit;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0;
  margin: 1rem 0 -0.3rem;
  text-align: left;
}

.button__showMore::after {
  content: "";
  display: block;
  background-color: var(--color-text-white);
  position: relative;
  top: 0.2rem;
  height: 0.0625rem;
}

.dropdownArrow {
  margin-left: 0.6rem;
  position: relative;
  top: 0.2rem;
  transition: 300ms ease;
}

.dropdownArrow.open {
  transform: rotate(180deg);
}

.time {
  font-weight: normal;
  margin-top: 0.5rem;
}

.challenges__heading {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 3rem;
  margin-bottom: 0;
}

.options__container {
  margin-top: 7rem;
  margin-bottom: 5rem;
}

.options__container button {
  border: none;
  background: none;
  color: var(--color-text-white);
  font-size: 1.5rem;
  margin-right: 4rem;
  margin-top: 3rem;
  transition: 100ms ease;
  will-change: transform;
}

.options__container button:hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.options__container button:active {
  transform: scale(0.98);
}

.options__container button svg {
  width: 3rem;
  height: 2rem;
  margin-right: 0.3rem;
  position: relative;
  top: 0.8rem;
}

.options__container button path {
  fill: var(--color-text-white);
}

.options__container button span::after {
  content: "";
  display: block;
  background-color: var(--color-text-white);
  position: relative;
  top: 0.2rem;
  height: 0.0625rem;
  margin-left: 3.5rem;
}
