@charset "utf-8"; /* This should fix the "–" character in ::before sometimes not displaying correctly */

.container {
  margin: 5rem auto;
}

.container {
  opacity: 0;
  transform: translateY(0.4rem);
  animation: fadeIn 800ms 300ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.container p {
  max-width: 80rem;
}

.container__labels {
  min-height: 10rem;
  background: rgba(255, 255, 255, 0.7);
  width: 100vw;
  position: relative;
  left: calc(0rem - var(--padding-site));
  padding: 2rem var(--padding-site);
  margin-top: 2rem;
}

.labels__header {
  margin: 0.5rem 0;
}

.labels__grid {
  display: grid;
  margin-top: 2rem;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
}

.labels__column {
  margin-bottom: 1rem;
}

.labels__list {
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
}

.inputs {
  width: 100%;
}

.inputs span {
  max-width: 70rem;
}

.inputs label {
  display: block;
  font-size: 1.5rem;
  margin: 2rem 0 0.2rem 0;
  width: 48%;
}

.inputs .subLabel {
  display: block;
  font-size: 0.75em;
}

.inputs input:not([type="submit"]),
.inputs select,
.inputs textarea {
  font-size: 1.5rem;
  width: 48%;
  font-family: inherit;
  border: var(--stroke-width-standard) solid var(--color-green-dark);
  padding: 0.5rem;
  margin-bottom: 1rem;
  background: var(--color-text-white);
}

.inputs textarea {
  resize: none;
}

.description {
  display: inline-block;
  width: 45%;
  margin-left: 4%;
  vertical-align: top;

  /* Allows line breaking using \n in translation strings   */
  white-space: pre-line;
}

.description div {
  margin-top: 30px;
  margin-bottom: 20px;
  height: 2px;
  width: 100%;
  background: #000;
}

.description span p {
  margin-bottom: 0;
}

.inputs ul {
  list-style: none;
  padding-left: 0;
}

.inputs ul > li {
  margin-left: 1ch;
}

.inputs ul > li::before {
  content: "– ";
  margin-left: -1ch;
}

.activities__container {
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.activities__container .header {
  width: 48%;
  display: inline-block;
  margin-top: 0;
}

.activity label:first-of-type {
  margin-top: 0.5rem;
}

/*
* This used to be a "position:absolute" but is now a hack
* around Safari failing to go back to "absolute" after
* media query change to "relative"
*/
.activities__mainDescription {
  margin-bottom: -150%;
}

.activity::after {
  content: "";
  display: block;
  height: var(--stroke-width-standard);
  width: 48%;
  background-color: var(--color-green-dark);
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.button {
  background: none;
  border: none;
  display: block;
  transition: 100ms ease;
  will-change: transform;
}

.button:not(:disabled):hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.button:active {
  transform: scale(0.98);
}

.dropdownArrow {
  stroke-width: 0.13rem;
  color: var(--color-green-dark);
  position: absolute;
  transform: translate(-2.5rem, 0.7rem) scale(1.2) scaleY(1.4);
  stroke-linecap: square;
  stroke-linejoin: inherit;
  pointer-events: none;
}

.finalButtons__container {
  margin-top: 4rem;
  margin-bottom: 7rem;
  width: 48%;
}

.saveError {
  color: var(--color-text-error);
  font-weight: bold;
  white-space: pre-line;
}

.finalButtons__container button {
  border: none;
  background: none;
  display: inline-block;
  font-size: 1.5rem;
  height: 3.875rem;
  margin-right: 3rem;
  margin-top: 2rem;
}

.finalButtons__container .save {
  background: var(--color-green-dark);
  color: var(--color-text-white);
  width: 12.5rem;
}

.finalButtons__container .save:disabled {
  opacity: 0.6;
}
.finalButtons__container .save:disabled:hover {
  transform: none;
}

.finalButtons__container button span {
  color: var(--color-green-dark);
}

.finalButtons__container button span::after {
  content: "";
  display: block;
  background-color: var(--color-green-dark);
  position: relative;
  top: 0.2rem;
  height: 0.0625rem;
  margin-left: 3.5rem;
}

.finalButtons__container button svg {
  width: 3rem;
  height: 1.8rem;
  margin-right: 0.3rem;
  position: relative;
  top: 0.5rem;
}

.saveButton__container {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
}

.saveButton__container .saved {
  display: block;
  font-size: 1.5rem;
  transform: scale(0.8);
  opacity: 0;
  animation: popUp 200ms ease forwards;
}

@keyframes popUp {
  90% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.saveButton__container .saved svg {
  margin-right: 0.5rem;
  position: relative;
  top: 0.5rem;
}

@media screen and (max-width: 1780px) {
  .labels__grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1020px) {
  .labels__grid {
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }
}

@media screen and (max-width: 750px) {
  .labels__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 540px) {
  .labels__grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 900px) {
  .inputs input:not([type="submit"]),
  .inputs select,
  .inputs textarea {
    width: 100%;
    margin-bottom: 0;
  }

  .inputs textarea {
    display: block;
  }

  .inputs label {
    width: 100%;
  }

  .container p {
    font-size: 1.5rem;
  }

  .description {
    display: block;
    width: 100vw;
    position: relative;
    left: calc(0rem - var(--padding-site));
    margin: 0;
    padding: 1rem var(--padding-site) 2rem;
    background: var(--color-green-light);
    font-size: 1.5rem;
  }

  .activities__container .header {
    width: 100%;
  }

  .activities__container .activity:first-of-type {
    margin-top: 0;
  }

  .activity::after {
    width: 100%;
    margin-top: 2rem;
  }

  .finalButtons__container {
    width: 100%;
  }
}
