.container {
  border: 2px solid #000;
  border-left: 0;
  border-right: 3px solid #000;
  width: 17rem;
  opacity: 0;
  transform: translateY(0.4rem);
  animation: fadeIn 500ms 100ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
