.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container .text {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-green-dark);
}
