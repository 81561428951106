.header {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.table {
  margin-bottom: 2rem;
  background: #fff;
  text-align: left;
  border-collapse: collapse;
  border: 1px solid var(--color-green-light);
  font-size: 1.5rem;
}

.table th:first-child {
  width: 100%;
}

.table td,
.table th {
  padding: 0.3rem;
}

.table tr:not(:last-child),
.table thead tr {
  border-bottom: 1px solid var(--color-green-light);
}

.pending {
  color: var(--color-green-dark);
  margin-left: 0.2rem;
}

.select {
  font-size: 1.5rem;
  font-family: inherit;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border-radius: 0;
  padding-right: 2rem;
  color: var(--color-green-dark);
}

.dropdownArrow {
  color: var(--color-green-dark);
  width: 0.8rem;
  position: absolute;
  transform: translate(-1.5rem, 0.5rem);
  pointer-events: none;
}

.button {
  border: none;
  background: none;
  font-size: 1.5rem;
  transition: 100ms ease;
  will-change: transform;
}

.button:hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.button:active {
  transform: scale(0.98);
}

.button.share {
  display: block;
  margin: 2rem 0;
  color: var(--color-green-dark);
}

.button.share span {
  margin-left: 0.6rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid var(--color-green-dark);
}

.button.share svg {
  position: relative;
  top: 0.4rem;
}

.button.done {
  background: var(--color-green-dark);
  color: var(--color-text-white);
  padding: 1rem 2.5rem;
  margin-top: 2.5rem;
  margin-right: 2rem;
}

.loading {
  font-size: 1.5rem;
  opacity: 0;
  animation: fadeIn 300ms 100ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 900px) {
  .table {
    min-width: 80%;
  }

  .table thead {
    display: none;
  }

  .table td {
    display: block;
    padding: 1rem 1rem 0.5rem;
  }

  .table tr {
    background: #fff;
  }
}
