.blockBodyScroll {
  overflow: hidden;
}

.background {
  position: fixed;
  background: #0206;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-green-lightest);
  max-width: 50rem;
  max-height: 90%;
  overflow: auto;
  z-index: 2;
  box-shadow: 0px 4px 4px #0005, 0px 10px 10px #0302;
}

.subSection {
  padding: 1rem 5rem;
}

.cookies {
  background-color: var(--color-green-light);
}

.hotjar {
  padding-bottom: 3rem;
}

.cookies h1 {
  font-size: 2.25rem;
  margin-bottom: -0.5rem;
  opacity: 0;
  animation: fadeIn 1000ms ease forwards;
}

.subSection .subHeader {
  margin-bottom: 0rem;
  opacity: 0;
  animation: fadeIn 1000ms 200ms ease forwards;
}

.subSection .description {
  font-size: 1.5rem;
  margin-top: 0rem;
  opacity: 0;
  animation: fadeIn 1000ms 200ms ease forwards;
}

.subSection .description a {
  color: inherit;
}

.button {
  border: none;
  background: none;
  color: inherit;
  font-size: 1.5rem;
  padding: 1rem;
  will-change: transform;
  margin-top: 1rem;
  opacity: 0;
  animation: fadeIn 1000ms 200ms ease forwards;
}

.button:hover {
  transform: scale(1.02);
  transition: 50ms ease;
}

.button:active {
  transform: scale(0.99);
}

.yesButton {
  background: var(--color-green-dark);
  color: var(--color-text-white);
}

.noButton {
  margin-left: 0.3rem;
  font-weight: bold;
  color: var(--color-green-dark);
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 900px) {
  .box {
    min-width: 90%;
  }
}
