.container {
  height: 50vh;
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  color: var(--color-green-dark);
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  white-space: pre-wrap;
}
