.container {
  /* background: var(--color-green-lightest); */
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  animation: fadeIn 200ms 50ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10vw;
  height: 10vw;
  background-color: var(--color-green-dark);
  border-radius: 100%;
  animation: zoom 1.5s infinite ease-in-out;
}

@keyframes zoom {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

.text {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: none;
  clip: rect(0 0 0 0);
}
