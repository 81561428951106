.background {
  background: linear-gradient(
    90deg,
    var(--color-green-lightest) 50%,
    var(--color-green-light) 50%
  );
}

.main {
  opacity: 0;
  transform: translateY(0.4rem);
  animation: fadeIn 500ms 100ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.findExistingPlan {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-green-dark);
  max-width: 70%;
  line-height: 2;
}

.findExistingPlan a {
  color: inherit;
  text-decoration: none;
}

.arrow {
  padding-left: 1rem;
  position: absolute;
  transition: 100ms ease-out;
}

.findExistingPlan:hover .arrow {
  transform: translateX(0.8rem);
}

.form__container {
  margin-top: 3rem;
}

.form {
  width: 100%;
}

.form label {
  display: block;
  font-size: 1.5rem;
  margin: 2rem 0 0.2rem 0;
  width: 48%;
}

.form input:not([type="submit"]),
.form select,
.form textarea {
  font-size: 1.5rem;
  width: 48%;
  font-family: inherit;
  border: var(--stroke-width-standard) solid var(--color-green-dark);
  padding: 0.5rem;
  margin-bottom: 1rem;
  background: var(--color-text-white);
}

.form select {
  padding-right: 3rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border-radius: 0;
}

.form textarea {
  resize: vertical;
}

.form a {
  color: initial;
}

.form ul {
  list-style: none;
  padding-left: 0;
}

.form ul > li {
  margin-left: 1ch;
}

.form ul > li::before {
  content: "– ";
  margin-left: -1ch;
}

.description {
  display: inline-block;
  width: 45%;
  margin-left: 3rem;
  vertical-align: top;

  /* Allows line breaking using \n in translation strings   */
  white-space: pre-line;
}

.dropdownArrow {
  width: 1.4rem;
  height: 1.4rem;
  color: var(--color-green-dark);
  position: absolute;
  transform: translate(-2rem, 1rem);
  pointer-events: none;
}

.subLabel {
  display: block;
  font-size: 0.75em;
}

.numberInput {
  max-width: 14rem;
  text-align: right;
}

.numberInput__container {
  display: inline-block;
  width: 48%;
}

.button {
  background: none;
  border: none;
  display: block;
  transition: 100ms ease;
  will-change: transform;
}

.button:hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.button:active {
  transform: scale(0.98);
}

.finalButtons__container {
  margin-top: 4rem;
  margin-bottom: 7rem;
  width: 48%;
}

.saveError {
  color: var(--color-text-error);
  font-weight: bold;
  white-space: pre-line;
}

.finalButtons__container button {
  border: none;
  background: none;
  display: inline-block;
  font-size: 1.5rem;
  height: 3.875rem;
  margin-right: 3rem;
  margin-top: 2rem;
}

.finalButtons__container .save {
  background: var(--color-green-dark);
  color: var(--color-text-white);
  width: 12.5rem;
}

.finalButtons__container .save:disabled {
  opacity: 0.6;
}
.finalButtons__container .save:disabled:hover {
  transform: none;
}

.finalButtons__container button span {
  color: var(--color-green-dark);
}

.finalButtons__container button span::after {
  content: "";
  display: block;
  background-color: var(--color-green-dark);
  position: relative;
  top: 0.2rem;
  height: 0.0625rem;
  margin-left: 3.5rem;
}

.finalButtons__container button svg {
  width: 3rem;
  height: 1.8rem;
  margin-right: 0.3rem;
  position: relative;
  top: 0.5rem;
}

.saveButton__container {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
}

.saveButton__container .saved {
  display: block;
  font-size: 1.5rem;
  transform: scale(0.8);
  opacity: 0;
  animation: popUp 200ms ease forwards;
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 500ms ease forwards;
}

@keyframes popUp {
  90% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.saveButton__container .saved svg {
  margin-right: 0.5rem;
  position: relative;
  top: 0.5rem;
}

.button__addSubject {
  color: var(--color-green-dark);
  font-size: 1.5rem;
  font-weight: bold;
}

.subjectContainer {
  margin-bottom: 1rem;
}

.gradeContainer {
  margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {
  .background {
    background: var(--color-green-lightest);
  }

  .form input:not([type="submit"]),
  .form select,
  .form textarea {
    width: 100%;
    margin-bottom: 0;
  }

  .form textarea {
    display: block;
  }

  .form label {
    width: 100%;
  }

  .description {
    display: block;
    width: 100vw;
    position: relative;
    left: calc(0rem - var(--padding-site));
    margin: 0;
    padding: 1rem var(--padding-site);
    background: var(--color-green-light);
    font-size: 1.5rem;
  }

  .finalButtons__container {
    width: 100%;
  }
}
