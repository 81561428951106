.container {
  border-bottom: 1px solid #000;
  display: flex;
}

a.day {
  color: inherit;
}

.day {
  background: none;
  border: none;
  padding: 0;
  display: block;
  text-align: left;
  cursor: default;
  border-right: 1px solid #828282;
  flex-basis: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  position: relative;
}

.day.weekNumber {
  max-width: 3rem;
}

.number {
  font-size: 0.875rem;
  padding-left: 0.5rem;
}

.partOfMonth {
  background: var(--color-green-light);
  border-color: #fff0;
}

.partOfMonth.hasPlan:not(.weekDay-6):not(.weekDay-7) {
  background: var(--planColor);
}

.partOfMonth.hasPlan:not(.weekDay-6):not(.weekDay-7) {
  cursor: pointer;
}

.partOfMonth.hasWriteAccessToYearPlan:not(.hasPlan):not(.weekDay-6):not(.weekDay-7):hover:after {
  position: absolute;
  content: "+";
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.weekDay-5 {
  border: none;
}

.weekDay-6,
.weekDay-7 {
  border: none;
  border-left: 1px solid #000;
  flex-basis: 50%;
}

.planTitle {
  position: absolute;
  display: block;
  padding-left: 0.3rem;
  width: calc(100% * var(--titleWidth));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
  pointer-events: none;
}
