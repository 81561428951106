.main {
  opacity: 0;
  animation: fadeIn 500ms 100ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.addPlan {
  background: none;
  border: none;
  background-color: var(--color-green-dark);
  color: var(--color-text-white);
  height: min-content;
  font-size: 1.5rem;
  padding: 1rem 3rem;
  margin-left: 2rem;
  margin: 2rem 0 2rem 2rem;
}

button.addPlan:hover {
  background-color: var(--color-green-middle);
}

.nav a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5rem;
  margin-right: 3rem;
  margin-bottom: 1rem;
  min-height: 3rem;
}

.nav a:hover:not(.activeLink)::after {
  content: "";
  display: block;
  position: relative;
  top: 0.5rem;
  height: var(--stroke-width-underline);
  background-color: var(--color-green-light);
}

.nav .activeLink::after {
  content: "";
  display: block;
  position: relative;
  top: 0.5rem;
  height: var(--stroke-width-underline);
  background-color: var(--color-green-dark);
}
