.quarterContainer {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, minmax(17rem, 1fr));
}

@media (max-width: 1100px) {
  .quarterContainer {
    grid-template-columns: minmax(17rem, 1fr);
  }
}
