.container {
  margin: 1rem 0;
  min-height: 22rem;
}

.year {
  font-size: 0.875rem;
  line-height: 1;
  margin: 0;
  padding: 0 0.5rem;
}

.monthName {
  font-size: 1.5rem;
  line-height: 1.4;
  margin: 0;
  padding: 0 0.5rem;
  border-bottom: 1px solid #000;
}
