.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 var(--padding-site);
  padding-top: 2rem;
  min-height: 6rem;
  font-size: 18px;
  background-color: var(--color-nav-background);
  color: var(--color-nav-text);
}

.logo {
  margin: 0;
  margin-right: 2rem;
  font-weight: bold;
  font-size: 18px;
}

.container a {
  color: var(--color-nav-text);
  text-decoration: none;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.list {
  list-style-type: none;
  display: flex;
  margin: 0;
}

.list > li {
  margin-right: 3rem;
}

.list a:hover:not(.activeLink)::after {
  content: "";
  display: block;
  position: relative;
  top: 0.2rem;
  height: var(--stroke-width-underline-nav);
  background-color: var(--color-green-dark);
}

.activeLink::after {
  content: "";
  display: block;
  position: relative;
  top: 0.2rem;
  height: var(--stroke-width-underline-nav);
  background-color: var(--color-green-light);
}

.user > button {
  color: inherit;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user button {
  border: none;
  background: none;
  padding: 0;
}

.dropdownArrow {
  margin-left: 0.6rem;
  position: relative;
  top: 0.2rem;
  width: 1rem;
}

.dropdown {
  background-color: var(--color-text-white);
  border: var(--stroke-width-standard) solid var(--color-green-dark);
  position: absolute;
  transform: translateY(0.5rem);
  right: var(--padding-site);
  visibility: hidden;
  max-height: 0rem;
  overflow: hidden;
  opacity: 0;
  transition: max-height 200ms ease, opacity 500ms ease;
  z-index: 999;
  color: var(--color-text-black);
}

.dropdown.open {
  visibility: visible;
  opacity: 1;
  max-height: 20rem;
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
}

.dropdown button {
  padding: 0.5rem 2rem;
  min-width: 10rem;
}

.dropdown button.activeLanguage {
  color: var(--color-green-dark);
  font-weight: bold;
}

.hamburger__header {
  display: none;
}

/*
Mobile Menu Styles
*/

.hamburger {
  color: var(--color-nav-text);
  display: none;
  border: none;
  background: none;
  margin: 0;
  height: 3rem;
}

.hamburger .line {
  display: inline-block;
  width: 1.3rem;
  height: 2px;
  background-color: var(--color-nav-text);
  position: absolute;
  transform: translate(-1.6rem, 0.3em);
}

.hamburger .line:nth-child(2) {
  transform: translate(-1.6rem, 0.6em);
}

.hamburger .line:nth-child(3) {
  transform: translate(-1.6rem, 0.9em);
}

.inHamburger.nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-green-light);
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem var(--padding-site) 30vh var(--padding-site);
  color: var(--color-text-black);
}

.inHamburger .hamburger__header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.hamburger__header button {
  border: none;
  background: none;
  height: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.backdrop {
  position: fixed;
  background: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.inHamburger .activeLink::after {
  background-color: var(--color-text-white);
}

.inHamburger .list {
  flex-direction: column;
  align-items: flex-start;
  font-size: 2rem;
  padding: 0;
}

.inHamburger .user > button {
  font-size: 2rem;
}

.inHamburger a,
.inHamburger .user > button {
  display: block;
  min-height: 5.5rem;
  margin: 0;
  color: var(--color-text-black);
}

.inHamburger .dropdown {
  left: var(--padding-site);
  right: initial;
}

@media screen and (max-width: 900px) {
  .hamburger {
    display: initial;
  }

  .nav {
    display: none;
  }

  .dropdown button {
    min-width: 12rem;
  }
}

@media only print {
  .container {
    display: none;
  }
}
