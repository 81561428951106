.text {
  position: absolute;
  z-index: 5;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--color-green-dark);
}
