.main {
  opacity: 0;
  transform: translateY(0.4rem);
  animation: fadeIn 500ms 100ms ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.background {
  background: linear-gradient(
    90deg,
    var(--color-green-lightest) 50%,
    var(--color-green-light) 50%
  );
}

.tools__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5rem;
}

.tool {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 1rem;
}

.tool .text a {
  color: inherit;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 0.3rem;
  border-bottom: 0.0625rem solid black;
}

.tool .text p {
  font-size: 1.5rem;
  margin-top: 0.5rem;
}

.tool .text .title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.tool .image {
  margin: 0;
  filter: drop-shadow(0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25));
}

.tool .image img {
  height: 14rem;
  width: 100%;
  object-fit: contain;
}

.tool .text .source {
  font-size: 1.2rem;
  color: #636363;
}

@media screen and (max-width: 1200px) {
  .tool {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 900px) {
  .background {
    background: var(--color-green-lightest);
  }

  .tools__container {
    grid-template-columns: 1fr;
  }

  .tool {
    grid-template-columns: 1fr;
  }
}
