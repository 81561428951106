.container {
  margin-bottom: 2rem;
}

.dropdownArrow {
  margin-left: 0.6rem;
  position: relative;
  top: 0.2rem;
  transition: 300ms ease;
}

.dropdownArrow.open {
  transform: rotate(180deg);
}

.container button.button {
  display: block;
  font-size: 1.5rem;
  margin: 2rem 0 0.2rem 0;
  background: none;
  border: none;
  border-bottom: 2px solid var(--color-green-dark);
  padding: 0;
  color: var(--color-green-dark);
}

.container .description {
  display: inline-block;
  width: 45%;
  margin-left: 4%;
  vertical-align: top;

  /* Allows line breaking using \n in translation strings   */
  white-space: pre-line;
}

@media screen and (max-width: 900px) {
  .container .description {
    display: block;
    width: 100vw;
    position: relative;
    left: calc(0rem - var(--padding-site));
    margin: 0;
    padding: 1rem var(--padding-site) 2rem;
    background: var(--color-green-light);
    font-size: 1.5rem;
  }
}
