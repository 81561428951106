.container label {
  display: block;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.container textarea {
  display: block;
  width: 100%;
  font-size: 1.5rem;
  font-family: inherit;
  border: var(--stroke-width-standard) solid var(--color-green-dark);
  padding: 0.5rem;
  background: var(--color-text-white);
}

.container input {
  font-size: 1.5rem;
  font-family: inherit;
  border: var(--stroke-width-standard) solid var(--color-green-dark);
  padding: 0.5rem;
  background: var(--color-text-white);
  width: 100%;
}

.inputs {
  display: flex;
  align-items: flex-end;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.inputs > div:first-child {
  flex-grow: 1;
  width: 100%;
}

.select {
  font-size: 1.5rem;
  font-family: inherit;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border-radius: 0;
  border: var(--stroke-width-standard) solid var(--color-green-dark);
  color: black;
  padding: 0.5rem;
  padding-right: 2rem;
  margin-left: 0.5rem;
}

.dropdownArrow {
  color: var(--color-green-dark);
  width: 1.4rem;
  height: 1.4rem;
  position: absolute;
  transform: translate(-2rem, 1rem);
  pointer-events: none;
}

.button {
  font-size: 1.5rem;
  border: none;
  background: none;
  transition: 100ms ease;
  will-change: transform;
}

.button:hover {
  transform: scale(1.04);
  transition: 50ms ease;
}

.button:active {
  transform: scale(0.98);
}

.button.send {
  background: var(--color-green-dark);
  color: var(--color-text-white);
  padding: 1rem 2.5rem;
  margin-top: 2.5rem;
}

.button.cancel {
  color: var(--color-green-dark);
  padding: 1rem;
}

@media screen and (max-width: 900px) {
  .inputs {
    flex-direction: column;
    align-items: flex-end;
  }

  .inputs input {
    margin-bottom: 1rem;
  }

  .select {
    margin: 0;
  }
}
